import React from 'react'
import { Link } from "react-router-dom"


const PlanScreen = () => {
  const token = localStorage.getItem('token')
  const PLANS = [
    {
      heading: "Free Trial",
      id: 1,
      price: 0,
      book: false,
      features: [
        "No Payment Information Needed",
        "See all charts and tables",
        "All Webinars",
        "Practice MCQ's / SCA",
        "Cancel Anytime",
        "No auto-pay committment"
      ],
      button: "Try for 7 Days",
      buttonColor: "#fff78a",
      buttonText: "#111",
      url: token ? "/userprofile" : "/signup"
    },
    {
      heading: "Monthly",
      id: 2,
      price: 4.99,
      book: false,
      features: [
        "Access all contents",
        "See all charts and tables",
        "All Webinars",
        "Cancel Anytime",
        "AKT MCQ's / SCA preparation",
        "No minimum term"
      ],
      button: "Subscribe Now",
      buttonColor: "#07358c",
      buttonText: "#fff",
      url: token ? "/userprofile" : "/signup"

    },
    {
      heading: "Book Pass",
      id: 3,
      price: 0,
      book: true,
      features: [],
      button: "Go to book Store",
      buttonColor: "#07358c",
      buttonText: "#fff",
      url: "/books"
    }
  ]

  return (
    <section className='pt-24 font-poppins plan-page'>
      <h1 className='plan-heading'>clear and simple plans</h1>
      <div className="w-[80%] sm:w-[65%] md:w-[80%] lg:w-[85%] 2xl:w-[75%] 3xl:w-[62%] mx-auto">
        <section className="grid w-full mt-0 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 lg:gap-5 xl:gap-10">
          { PLANS.map(plan => <article key={ plan.id } className={ `rounded-[53px] py-4 px-5 flex gap-10 flex-col bg-[#eef5ff] border-[8px] shadow-2xl border-[#292f3f] relative overflow-hidden ${plan.book && "book-card"}` }>
            { plan.book &&
              <>
                <div className="overlay" />
                <figure className='w-full h-full px-4 pt-24 absolute top-0 left-0'>
                  <img className='w-full h-auto' alt='book cover' src='https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/645f4fe6f2dfc18979e960e4/books/ee30e971-1ed4-4cbf-b01a-929916a55e9b.jpg' />
                </figure>
              </>
            }
            <h2 className='font-bold text-5xl text-center py-2 rounded-full bg-[#c6fff7]' >{ plan.heading }</h2>

            { plan.book && <h3 style={ { lineHeight: 1.4 } } className='text-5xl mt-auto mb-auto font-bold text-center text-[#fbff19]'>Buy any book get 12 months full access</h3> }

            { !plan.book && <>
              <h3 className='text-center text-[#bd292e] text-6xl font-semibold flex items-center flex-col gap-2'>  £ { plan.price } { plan.price > 0 && <span className='uppercase text-lg text-black'>per month</span> } </h3>
              <ul className="flex flex-col gap-5 font-[600] text-xl pl-10 mt-auto mb-auto">
                { plan.features.map((feature, i) => <li className='list-disc text-xl font-[600]' key={ i }>{ feature }</li>) }
              </ul>
            </>
            }

            <Link to={ plan.url } style={ { background: plan.buttonColor, color: plan.buttonText } } className={ `w-full rounded-full text-center px-2 py-3 font-bold mb-2 transition-all hover:brightness-95 inline-block` }>
              { plan.button }
            </Link>
          </article>) }
        </section>
      </div>
    </section>
  )
}

export default PlanScreen