import React, { useEffect, useState } from 'react'
import { FaUserCircle } from 'react-icons/fa'
// import { testimonials } from '../constants'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import API from '../api'
import { displayStars } from '../utility'
import ReadMore from './ReadMore'
import HTMLRenderer from './InnerHtml'
import PopFeature from './Popfeature'
const Client = () => {
  const [feedbacks, setFeedbacks] = useState([])

  const [isFeature, setFeature] = useState(false)
  const [currentIndex, setCurrentIndex] = useState()
  const [desc, setDesc] = useState(null)

  const handleStatClick = ({ index, desc }) => {
    setFeature(true)
    setCurrentIndex(index)
    setDesc(desc)
  }

  const handleOnStatClose = () => {
    setFeature(false)
    setCurrentIndex(undefined)
    setDesc(desc)
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 764 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 764, min: 0 },
      items: 1,
    },
  }

  const truncateWord = (words, maxWords) => {
    if (words.length > maxWords) {
      // If yes, truncate the text to the first 'maxWords' words
      const truncatedText = words.slice(0, maxWords)

      // Add a "Read More" option
      const readMoreText = `${truncatedText}... `

      return readMoreText
    }
    // If the text has fewer than or equal to 'maxWords' words, return the original text
    return words
  }

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const res = await fetch(
          `https://a4medicine.co.uk/api/v1/feedback/?status=approved`
        )
        const data = await res.json()

        setFeedbacks(data.data.feedbacks.filter((el) => el.isShow))
      } catch (err) {
        console.error(err)
      }
    }

    fetchFeedbacks()
  }, [])

  return (
    <div className='py-24 bg-white'>
      <div className='container max-w-6xl w-[100%] m-auto px-4'>
        <div className='section-title mb-5 flex justify-center flex-col items-center'>
          <h2 className='flex flex-row text-4xl font-bold'>
            Our &nbsp; <span> Testimonials</span>
          </h2>
          <p className='text-xl font-medium'>what client say about us?</p>
        </div>

        {feedbacks.length === 0 && (
          <h2 className='text-center text-3xl font-bold'>No Data Found !!</h2>
        )}

        {feedbacks.length > 0 && (
          <div className=''>
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlaySpeed={5000}
              autoPlay={true}
              keyBoardControl={false}
              transitionDuration={800}
              showDots={true}
              arrows={false}>
              {feedbacks.map((el, i) => (
                <div
                  className='bg-[#0058ab] flex flex-col justify-between p-10 m-4 h-[400px] rounded-lg'
                  key={el.id}>
                  <div className='text-4xl text-white mb-2'>
                    <i className='fa-solid fa-quote-left'></i>
                  </div>
                  <p className='text-xl font-[500] text-white text-justify'>
                    {truncateWord(el.feedback, 150)}
                    {el.feedback.length > 150 && (
                      <span
                        className='ml-[120px] text-green-400 cursor-pointer md:ml-[170px]'
                        onClick={() =>
                          handleStatClick({ index: i, desc: el.feedback })
                        }>
                        Read More
                      </span>
                    )}
                  </p>

                  <div className='flex justify-between pt-6 relative bottom-0 items-center'>
                    <div className='client-pic'>
                      {el.image ? (
                        <img
                          src={`https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/${el.image}`}
                          alt='user pic'
                          className='w-16 aspect-square rounded-full object-cover object-center'
                        />
                      ) : (
                        <FaUserCircle className='text-4xl text-white' />
                      )}
                    </div>
                    <div className='text-white text-[14px] font-[400]'>
                      <h6 className='text-right text-base'>{el.name}</h6>
                      <div className='flex items-center gap-1 text-2xl'>
                        {displayStars(el.rating)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
            {isFeature && desc != null && (
              <PopFeature
                onClose={handleOnStatClose}
                visible={isFeature}
                desc={desc}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Client
