import React from 'react'
import { FaComment, FaList, FaVideo } from 'react-icons/fa'
import { IoBook } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import CaseWrapper from '../wrappers/CaseWrapper'

const cards = [
  {
    id: 1,
    heading: 'SCA Cases',
    icon: <FaList className='text-6xl' />,
    subHeading: 'Listed by presenting complaint',
    description:
      'Click below to access the list of SCA cases listed by presenting complaints. This will suit those who prefer to test themselves with conditions similar to the SCA Exam.',
    to: '/sca-cover',
  },
  {
    id: 2,
    heading: 'Consultation Guide',
    icon: <IoBook className='text-6xl' />,
    subHeading: undefined,
    description:
      'This is our detailed “How to handle” consultation guide for the most difficult and challenging presentations. Examples include the ‘Angry patient’, ‘Breaking bad news’, and ‘ How to Demonstrate empathy’. ',
    to: '/consultation-guide',
  },
  {
    id: 3,
    heading: 'Consultation Skills',
    icon: <FaComment className='text-6xl' />,
    subHeading: undefined,
    description:
      'Read our overview on Consultation Skills in each marking domain of the exam. Alternatively click below to read our individual guides.',
    to: '/consultation-skills',
  },
  {
    id: 4,
    heading: 'SCA Explanation',
    icon: <FaVideo className='text-6xl' />,
    subHeading: undefined,
    description:
      'Welcome to our random condition generator. Pick a condition yourself, or let the website pick a condition for you to practice your explanations. When the time is up, or at the click of a button, we’ll show you how we would explain it!',
    to: '/sca-explanation',
  },
]

const ScaScreen = () => {
  window.scrollTo(0, 0)
  return (
    <CaseWrapper heading='Case Revision Portal'>
      <section className='max-[1160px]:px-10 max-sm:px-5'>
        <ul className='w-[1150px] max-[1160px]:w-full mx-auto grid-cols-3 grid max-lg:grid-cols-2 gap-x-5 gap-y-6 max-md:grid-cols-1 pb-16 max-lg:pb-10 max-md:pb-5'>
          {cards.map((crd) => (
            <li
              key={crd.id}
              className='w-full shadow-lg rounded-md bg-[#dfebff] flex flex-col items-center gap-5 py-8 px-9 text-[#16243b]'>
              {crd.icon}

              <h2 className='text-4xl font-bold'>{crd.heading}</h2>

              <h4 className='text-xl font-bold text-black self-stretch mt-3'>
                {crd.subHeading}
              </h4>

              <p className='text-black self-stretch -mt-3'>{crd.description}</p>
              <Link
                to={crd.to}
                className='bg-[#213555] text-white py-4 px-6 rounded-md mt-auto'>
                Get Started
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </CaseWrapper>
  )
}

export default ScaScreen
