import React, { useEffect, useState } from 'react'
import CaseWrapper from '../wrappers/CaseWrapper'
import API from '../api';
import { useNavigate } from 'react-router-dom';
import { replaceSpaces } from '../utility';
import Loading from '../components/Loading';
import { Link } from "react-router-dom"

const BooksScreen = () => {
  const [loading, setLoading] = useState(false)
  const [books, setBooks] = useState([])
  const navigate = useNavigate()
  window.scrollTo(0, 0)

  useEffect(() => {
    const fetchAllBooks = async () => {
      try {
        setLoading(true)
        const res = await fetch(API + "book");
        const data = await res.json();


        console.log(data?.data?.books);
        setBooks(data?.data?.books || [])
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false)
      }
    };

    fetchAllBooks();
  }, []);

  return (
    <CaseWrapper heading='Books'>
      <section className='max-[1160px]:px-10 max-sm:px-5'>
        { loading && <Loading color={ '#213555' } visible={ true } /> }{ ' ' }
        <ul className='w-[1150px] max-[1160px]:w-full mx-auto grid-cols-3 grid max-lg:grid-cols-2 gap-x-10 gap-y-6 max-md:grid-cols-1 pb-16 max-lg:pb-10 max-md:pb-5'>

          { !loading &&
            books.map(book => {
              const bookLink = `/book/${replaceSpaces(book.name, '_')}+${book.id}`

              return <li className='flex flex-col gap-2 items-center py-6 px-3 border rounded-lg' key={ book.id }>
                <img role='button' onClick={ () => navigate(bookLink) } src={ `https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/${book.image}` } alt="" className='w-full aspect-[1/1] object-contain' />

                <h3 className='text-center text-lg'>{ book.name }</h3>

                <h4 className='text-xl font-semibold'>£ { book.price }</h4>

                <div className="flex items-center">

                  <Link to={ bookLink } className='flex items-center justify-center border-2 bg-[--main-color] text-white font-semibold px-8 mt-4 text-base md:text-xl h-12 hover:brightness-110'>
                    Know More
                  </Link>
                </div>
              </li>
            })
          }
        </ul>
      </section>
    </CaseWrapper>
  )
}

export default BooksScreen