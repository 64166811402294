import { useLocation, Navigate, useParams, useNavigate } from 'react-router-dom'
import CaseWrapper from '../wrappers/CaseWrapper'
import { useFetch } from '../hooks/useFetch'
import Loading from '../components/Loading'
import { replaceSpaces } from '../utility'

const ScaCasesScreen = () => {
  window.scrollTo(0, 0)
  const id = useParams()?.id?.split('+')

  const { loading, data } = useFetch(`sca-case/cover/${id[1]}`)
  const token = localStorage.getItem('token')
  const subscriptionId = localStorage.getItem('subscriptionId')
  const location = useLocation()
  const navigate = useNavigate()

  if (!token || (token && subscriptionId && subscriptionId === 'none'))
    return <Navigate to='/subscription' state={{ from: location }} replace />

  const cases = data?.ScaCase || []

  return (
    <CaseWrapper heading={id[0].replaceAll('`', ' ')}>
      {loading && <Loading color={'#213555'} visible={true} />}{' '}
      <section className='max-[1160px]:px-10 max-sm:px-5'>
        <ul className='w-[1150px] max-[1160px]:w-full mx-auto flex flex-col gap-5 pb-16 max-lg:pb-10 max-md:pb-5'>
          {!loading &&
            cases.map((c) => (
              <li
                key={c.id}
                className='w-full py-3 cus_list text-xl'
                role='button'
                onClick={() =>
                  navigate(`/sca-case/${replaceSpaces(c.heading, '`')}+${c.id}`)
                }>
                {c.heading}
              </li>
            ))}

          {!loading && cases.length === 0 && (
            <p className='col-span-3 text-center text-4xl font-bold'>
              OOPS!! No Cases Found
            </p>
          )}
        </ul>
      </section>
    </CaseWrapper>
  )
}

export default ScaCasesScreen
