import React from "react";
import PlanCard from "./PlanCard";
import { useStateContext } from "../context/StateContext";
import { Link } from "react-router-dom";

const Subscription = () => {
  const { currentPlan, setCurrentPlan } = useStateContext();
  return (
    <div className="flex flex-col items-center bg-[#2e648b] p-1">
      <div className="mb-2 mt-8 text-center">
        <h1 className="mb-1 text-2xl font-black text-white md:mb-4">Pricing</h1>
        <p className="text-lg text-white p-5">
          Unlocking Primary Care Education: Making it Accessible and Affordable
        </p>
      </div>
      <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 p-8 gap-10 xl:grid-cols-2">
        <PlanCard
          color="#9DC08B"
          name="Free Trial"
          price={0.0}
          features={[
            "No Cards Required",
            "See all Charts and Tables",
            "All Webinar",
            "Practice MCQ's",
            "Cancel Any time",
            "No auto-pay committment",
          ]}
          onclick={() => setCurrentPlan("Free")}
          btnText="Try for 7 Days"
          notRequired={[]}
        />

        <PlanCard
          color="#FFD700"
          name="Monthly"
          price={4.99}
          features={[
            "Access all content",
            "See all Charts and Tables",
            "All Webinar",
            "Cancel Any time",
            "AKT MCQ's",
          ]}
          onclick={() => setCurrentPlan("gold")}
          btnText="Subscribe Now"
          notRequired={[]}
        />

        <div className="flex min-h-[400px] w-[280px] bg-[#bc9c69] flex-col rounded-3xl p-6  text-center shadow-lg">
          <h2 className="mb-3 text-4xl font-bold">Free Access with books</h2>

          <p className="my-auto text-4xl font-bold">
            Buy any book-12 month access included
          </p>

          <Link
            to="/book/Visual_Guidebook_Of_Basic_And_Essential_General_Practice_&_Family_Medicine!+64953bbeea5bc673e346683a"
            className="mt-auto rounded-xl bg-black h-12 flex items-center justify-center text-xl font-bold text-white text-center"
            onClick={() => window.scrollTo(0, 0)}
          >
            Shop Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
