import React, { useState, useRef, useContext, useEffect } from "react";
import logo from "../assets/logo.png";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import scrollToTop from "./scrollToTop";
import SearchBar from "./SearchBar";
import toast, { Toaster } from "react-hot-toast";
import { useStateContext } from "../context/StateContext";
import API from "../api";
import { FaUser } from "react-icons/fa";
import { CartContext } from "../context/CartContext";
import { replaceSpaces } from "../utility";
import { TbDeviceMobileDown } from "react-icons/tb";

let Links = [
  {
    name: "HOME",
    link: "/",
    idn: 0,
  },
  {
    name: "MEDICAL TOPICS",
    link: "/chart",
    idn: 1,
  },
  {
    name: "REVISION",
    link: "",
    submenu: true,
    id: "",
    subLinks: [],
    idn: 2,
  },
  {
    name: "BOOKS",
    link: `/books`,
    // id: "64953bbeea5bc673e346683a",
    // subLinks: [
    //   {
    //     name: "Visual Guidebook",
    //     link: "/book/Visual_Guidebook",
    //     id: "64953bbeea5bc673e346683a",
    //   },
    //   {
    //     name: "Essentials of Cancer Medicine",
    //     link: "/book/Essentials_of_Cancer_Medicine",
    //     id: "64953c90ea5bc673e346683d",
    //   },
    // ],
    idn: 3,
  },
  {
    name: "WEBINARS",
    link: "/webinar/past",
    submenu: true,
    subLinks: [
      { name: "Future Webinars", link: "/webinar/future", id: "future" },
      {
        name: "Past Webinars",
        link: "/webinar/past",
        id: "past",
      },
    ],
    idn: 4,
  },
  {
    name: "PANELISTS",
    link: "/pannellist",
    idn: 5,
  },
  {
    name: "PLANS",
    link: "/plans",
    idn: 6,
  },
  {
    name: "CHAT ZONE",
    link: "https://discord.gg/hfEuYtjWrB",
    idn: 7,
  },
];

const Navbar = () => {
  const searchRef = useRef();
  const firstName = localStorage.getItem("firstName");
  const location = useLocation();

  let [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(true);
  const token = localStorage.getItem("token");
  const ctx = useContext(CartContext);
  const [links, setLinks] = useState(Links);

  const [selectsearch, setSelectSearch] = useState("");
  const { results, setResults } = useStateContext();
  const { dis, setDis } = useStateContext();
  const { name, setName, setToken, setSubscriptionId } = useStateContext();

  const handleSearch = async (e) => {
    if (!e.currentTarget.value) {
      return setResults([]);
    }
    try {
      // Make API request to fetch search results
      const response = await fetch(`${API}/search/?search=${e.target.value}`);
      const dat = await response.json();
      if (dat.status === "success" && e.target.value.length > 3) {
        setResults(dat.data.searchResults);
      }
    } catch (error) {
      console.error(error);
      // Handle the error, e.g., show an error message to the user
    }
  };

  if (results.length > 3) {
    setDis(false);
  } else {
    setDis(true);
  }

  const logout = () => {
    toast.success("You are now logged out of A4Medicine!");

    localStorage.clear();
    setName("");
    setToken("");
    setSubscriptionId("");
    ctx.resetCart();
  };

  const currentUrl = (result) => {
    let links = "";
    if (result.type === "webinar") {
      links = "/webinar";
    }
    if (result.type === "pannellist") {
      links = "/pannellist";
    }
    if (result.type === "section") {
      if (token) {
        links = "/chart/details";
      } else {
        links = "/subscription";
      }
    }
    if (result.type === "book" && result._id === "64953c90ea5bc673e346683d") {
      links = "/book";
    }
    if (result.type === "book" && result._id === "64953bbeea5bc673e346683a") {
      links = "/book";
    }
    if (result.type === "chart") {
      links = "/chart";
    }
    if (result.type === "quiz") {
      links = "/quiz";
    }

    return links;
  };

  const { savedResult, setSavedResult } = useStateContext();

  const handleGoButtonClick = () => {
    setSavedResult(results);
    setResults([]);
  };

  useEffect(() => {
    const firstName = localStorage.getItem("firstName");
    if (firstName) setName(firstName);
  }, [setName]);

  useEffect(() => {
    const fetchAllBooks = async () => {
      try {
        const res = await fetch(API + "book");
        const data = await res.json();
        // /v2
        const examRes = await fetch("https://a4medicine.co.uk/api/v1/exam");
        const examData = await examRes.json();

        // console.log(examData.data.exams);

        const exams = examData.data.exams.map((el) => ({
          name: el.name,
          link: "/exam/" + replaceSpaces(el.name, "_") + `+${el.id}`,
          id: el.id,
        }));

        exams.push({
          name: "SCA Cases",
          link: "/sca",
          id: 1,
        });

        // const books = data.data.books.map((el) => ({
        //   name: el.name,
        //   link: "/book/" + replaceSpaces(el.name, "_") + `+${el.id}`,
        //   id: el.id,
        // }));

        setLinks((lst) => {
          const links = [...lst];

          // const index = links.findIndex((el) => el.name === "BOOKS");
          const examIndex = links.findIndex((el) => el.name === "REVISION");

          // links[index].subLinks = books;
          // links[index].link = books[0].link;
          // links[index].id = books[0].id;
          links[examIndex].subLinks = exams;

          return links;
        });
      } catch (err) {
        console.error(err);
      }
    };

    fetchAllBooks();
  }, []);

  function getFirstFourWords(inputString) {
    const words = inputString.split(" ");
    const firstFourWords = words.slice(0, 6).join(" ");
    if (firstFourWords.length < inputString.length)
      return firstFourWords + ".....";
    else return firstFourWords;
  }


  return (
    <>
      <div
        style={ { display: visible ? "visible" : "none" } }
        className="left-0 lg:pr-5 right-0 bg-[#213555] text-white top-0 z-[999] space-x-4 w-[full] h-[100px] md:h-[70px] fixed flex justify-between items-center flex-col md:flex-row bg-gradient-to-b"
      >
        <Toaster />

        <div className="flex flex-wrap items-center justify-center">
          <h1 className="md:text-xl text-[16px] font-semibold md:text-left text-center flex justify-start items-center md:mx-4 mx-0 my-1">
            { " " }
            <i className="fas fa-plus text-[#EFFF10] text-3xl animate-spin-slow z-10"></i>
            &nbsp; "Empower Your RCGP AKT Journey: Master the MCQs with Us! 🚀"
            &nbsp;
            <i className="fas fa-plus text-[#EFFF10] text-3xl animate-spin-slow z-10 "></i>
          </h1>
          <div className="flex gap-2">
            <button className="bg-white text-[#010767] font-bold p-2">
              <Link
                to={ token ? "/exam/AKT_Revision-_MRCGP+6547a4ade70f2eb3adb30f4e" : "/signup" }
                onClick={ scrollToTop }
              >
                Try Now
              </Link>
              !
            </button>
          </div>
        </div>
        <div className="lg:flex hidden">
          { token && name ? (
            <div className="flex gap-4 items-center">
              <Link
                to={ "/" }
                onClick={ logout }
                className="text-sky-600 uppercase bg-white px-6 py-2 text-base rounded font-semibold border-2 border-white hover:bg-transparent transition-all hover:text-white"
              >
                Logout
              </Link>
              <Link
                to="/userprofile"
                className="flex items-center gap-3 text-xl font-bold capitalize"
                onClick={ scrollToTop }
              >
                <FaUser />
                { name }
              </Link>

              <Link to={ "/cart" } className="relative">
                <span className="w-5 aspect-square absolute top-[-45%] right-[-50%] rounded-full text-[14px] flex justify-center items-center bg-sky-400">
                  { ctx?.cart?.length }
                </span>
                <AiOutlineShoppingCart className="text-3xl" />
              </Link>
            </div>
          ) : (
            <div className="flex gap-5 items-center">
              <Link
                to="/login"
                className="font-bold border-2 border-white px-6 py-2 rounded hover:bg-white hover:text-sky-600 transition-all text-base"
                onClick={ scrollToTop }
              >
                LOGIN
              </Link>
              OR
              <Link
                to="/signup"
                onClick={ scrollToTop }
                className="text-sky-600 bg-white px-6 py-2 text-base rounded font-semibold border-2 border-white hover:bg-transparent transition-all hover:text-white"
              >
                REGISTER
              </Link>
              <Link to={ "/cart" } className="relative">
                <span className="w-5 aspect-square absolute top-[-45%] right-[-50%] rounded-full text-[14px] flex justify-center items-center bg-sky-400">
                  { ctx?.cart?.length }
                </span>
                <AiOutlineShoppingCart className="text-3xl" />
              </Link>
            </div>
          ) }
        </div>
      </div>

      <div
        className={ `shadow-md w-full fixed top-0 z-[50] ${visible ? "mt-[100px]" : "mt-[0px]"
          } ${visible ? "md:mt-[65px]" : "md:mt-[0px]"} bg-white left-0 px-4` }
      >
        <div className="flex items-center justify-between p-2">
          <div className="font-bold text-2xl cursor-pointer sm:flex items-center font-[Poppins] text-gray-800">
            <Link to="/" onClick={ scrollToTop }>
              <img
                src={ logo }
                alt=""
                className="md:h-[50px] h-[50px] align-middle pb-1"
              />
            </Link>
          </div>

          <ul
            className={ `lg:flex lg:items-center lg:pb-0 pb-12 absolute lg:static backdrop-filter backdrop-blur-lg bg-white lg:z-auto left-0 w-full lg:w-auto lg:pl-0 pl-9 transition-all duration-500 ease-in ${open ? "right-20 top-[5px]" : "top-[-600px]"
              }` }
          >
            { links.map((link) => {
              if (link.idn >= 2 && link.idn <= 4) {
                return (
                  <button
                    key={ link.name }
                    className="lg:ml-5 block text-base lg:my-0 -my-1.5   cus_link cursor-pointer relative"
                    onClick={ () => {
                      setOpen(false);
                      scrollToTop();
                    } }
                  >
                    <Link
                      to={ link.link }
                      className="text-[--main-color] font-[600] hover:text-[#F17732] flex duration-500 "
                    >
                      { link.name }
                      { "" }
                      { link.submenu && (
                        <div className="sub_menu ml-3 relative lg:absolute top-full lg:left-1/2 lg:translate-x-[-50%] w-max hidden">
                          <div className="h-6 bg-transparent" />
                          <div className="bg-white">
                            { link.subLinks.map((mySubLinks) => {
                              return (
                                <div
                                  key={ mySubLinks.id }
                                  className="py-2 px-6 max-w-[25rem] border-b"
                                >
                                  <button onbuttonck={ scrollToTop }>
                                    <Link
                                      to={ mySubLinks.link }
                                      className="text-xs lg:text-base hover:text-[#F17732] hover:scale-75 text-gray-600 my-2.5"
                                    >
                                      { getFirstFourWords(mySubLinks.name) }
                                    </Link>
                                  </button>
                                </div>
                              );
                            }) }
                          </div>
                        </div>
                      ) }
                    </Link>
                  </button>
                );
              } else
                return (
                  <li
                    key={ link.name }
                    className="lg:ml-5 text-base lg:my-0 my-2 cus_link cursor-pointer relative"
                    onClick={ () => {
                      setOpen(false);
                      scrollToTop();
                    } }
                  >
                    <Link
                      to={ link.link }
                      className="text-[--main-color] font-[600] hover:text-[#F17732] flex duration-500 "
                    >
                      { link.name }
                      { "" }
                      { link.submenu && (
                        <div className="sub_menu relative lg:absolute top-full lg:left-1/2 lg:translate-x-[-50%] w-max hidden">
                          <div className="h-6 bg-transparent" />
                          <div className="bg-white">
                            { link.subLinks.map((mySubLinks) => {
                              return (
                                <div
                                  key={ mySubLinks.id }
                                  className="py-2 px-6 max-w-[25rem] border-b"
                                >
                                  <li>
                                    <Link
                                      onClick={ scrollToTop }
                                      to={ mySubLinks.link }
                                      className="text-xs lg:text-xl hover:text-[#F17732] hover:scale-75 text-gray-600 my-2.5"
                                    >
                                      { getFirstFourWords(mySubLinks.name) }
                                    </Link>
                                  </li>
                                </div>
                              );
                            }) }
                          </div>
                        </div>
                      ) }
                    </Link>
                  </li>
                );
            }) }

            <div className="lg:hidden flex">
              { token ? (
                <div className="flex gap-6">
                  <Link
                    to="/userprofile"
                    className="text-xl font-bold"
                    onClick={ () => {
                      setOpen(false);
                      scrollToTop();
                    } }
                  >
                    <button className="bg-transparent border-[--main-color] border-2 rounded-md text-center ml-10 hover:text-[#F17732] hover:border-[#F17732] h-[3rem] w-[7rem]  mt-2 text-[--main-color]  border-spacing-2">
                      { firstName }
                    </button>
                  </Link>
                  <Link
                    onClick={ () => {
                      setOpen(false);
                      scrollToTop();
                      logout();
                    } }
                    className="font-sans"
                  >
                    <button className="bg-[--main-color]  border-2 rounded-md text-center  hover:text-[#F17732] hover:border-[#F17732] h-[3rem] w-[7rem]  mt-2 text-white  border-spacing-2">
                      Logout
                    </button>
                  </Link>
                </div>
              ) : (
                <div className="flex gap-6">
                  <Link
                    to="/login"
                    className="text-xl font-bold"
                    onClick={ () => {
                      setOpen(false);
                      scrollToTop();
                    } }
                  >
                    <button className="bg-transparent border-[--main-color] border-2 rounded-md text-center ml-10 hover:text-[#F17732] hover:border-[#F17732] h-[3rem] w-[7rem]  mt-2 text-[--main-color]  border-spacing-2">
                      LOGIN
                    </button>
                  </Link>
                  <Link
                    to="/signup"
                    className="font-sans"
                    onClick={ () => {
                      setOpen(false);
                      scrollToTop();
                    } }
                  >
                    <button className="bg-[--main-color]  border-2 rounded-md text-center  hover:text-[#F17732] hover:border-[#F17732] h-[3rem] w-[7rem]  mt-2 text-white  border-spacing-2">
                      REGISTER
                    </button>
                  </Link>
                </div>
              ) }
            </div>
          </ul>

          <div className="flex items-center">
            <div className="">
              { location.pathname !== "/" && window.innerWidth > 1024 && (
                <SearchBar
                  className="md:flex hidden"
                  onSearch={ handleSearch }
                  searchRef={ searchRef }
                  disable={ dis }
                />
              ) }

              { window.innerWidth < 1024 && (
                <SearchBar
                  className="md:flex hidden"
                  onSearch={ handleSearch }
                  searchRef={ searchRef }
                  disable={ dis }
                />
              ) }

              {/* Display the search results */ }

              { results?.length > 0 ? (
                <ul className="absolute bg-white max-h-64 mt-2 p-2 rounded shadow-md overflow-y-auto">
                  { results.map((result, id) => {
                    let url = currentUrl(result);
                    return (
                      <Link
                        to="/search"
                        onClick={ () => {
                          searchRef.current.value = result.name;
                          handleGoButtonClick();
                          setSelectSearch(result.name);
                          scrollToTop();
                        } }
                        key={ id }
                      >
                        <li>{ result.name }</li>
                      </Link>
                    );
                  }) }
                </ul>
              ) : null }
            </div>
          </div>

          <div className="right-8 lg:hidden ml-auto px-2">
            <Link to={ "/cart" } className="relative">
              <span className="w-5 aspect-square absolute top-[-40%] right-[-50%] rounded-full text-[14px] flex justify-center items-center bg-sky-400">
                { ctx?.cart?.length }
              </span>
              <AiOutlineShoppingCart className="text-3xl" />
            </Link>
          </div>

          <div
            className="flex justify-center text-3xl right-8 md:top-8 top-7 cursor-pointer lg:hidden"
            onClick={ () => setOpen((lst) => !lst) }
          >
            <ion-icon name={ open ? "close" : "menu" }></ion-icon>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
//
