import React from 'react'
import { TbDeviceMobileDown } from 'react-icons/tb'

const AppDownloadButton = () => {
  const appDownloadHandler = () => {
    const appURL = '/a4medicine.apk'
    const aTag = document.createElement('a')
    aTag.href = appURL
    aTag.setAttribute('download', appURL.split('/')[1])
    document.body.appendChild(aTag)
    aTag.click()
    document.body.removeChild(aTag)
  }
  return (
    <div className='fixed bottom-10 left-10 z-[1800]'>
      <button
        type='button'
        onClick={appDownloadHandler}
        className='appBtn rounded-full bg-[--main-color] text-white shadow-xl  relative z-[2000] h-16 aspect-square flex items-center justify-center'>
        <TbDeviceMobileDown className='text-4xl' />
      </button>
      <p className='appInfo origin-right absolute left-0 top-0 w-16 text-xl overflow-hidden h-full rounded-full bg-white border-[3px] border-[--main-color] flex items-center shadow-xl font-semibold transition-all text-ellipsis whitespace-nowrap'>
        Download A4Medicine Mobile App
      </p>
    </div>
  )
}

export default AppDownloadButton
