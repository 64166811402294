import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import ReadMore from './ReadMore'
import toast from 'react-hot-toast'
import API from '../api'
import HTMLRenderer from './InnerHtml'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import PopChart from "./PopChart"

const ChartDetail = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const id = useParams()?.id.split('+').at(-1)
  const [data, setData] = useState('')
  const [loading, setLoading] = useState(false)
  const token = localStorage.getItem('token')
  const subscription = localStorage.getItem('subscriptionId')
  const [isOpen, setIsOpen] = useState(false)
  const [chartsImages, setChartsImages] = useState([])
  const [activeChartImgIndex, setActiveChartImgIndex] = useState(0)


  const handleClick = () => {
    setIsOpen(true)
  }
  const handleOnClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      try {
        const response = await fetch(`${API}section/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (!response.ok && response.status === 403)
          throw new Error("You don't have a Active Plan Please Buy One")

        const dat = await response.json()

        console.log("CHART DETAILS:", dat);

        if (dat.status === 'success') {
          setData(dat?.data)
          setChartsImages(dat?.data?.images || [])
          setLoading(false)
        } else {
          toast.error(dat.message)
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
        if (error.message.includes('Plan')) {
          window.scrollTo(0, 0)
          return navigate('/userprofile')
        }
      }
    }

    token && id && getData()
  }, [id, token, navigate])

  const formatText = data?.body?.split('\n').map((item, index) => (
    <React.Fragment key={ index }>
      { item }
      <br />
    </React.Fragment>
  ))

  const Loading = () => {
    return (
      <>
        <div className='cols-md-3'>
          <Skeleton height={ 350 } />
        </div>
        <div className='col-md-3'>
          <Skeleton height={ 350 } />
        </div>
        <div className='col-md-3'>
          <Skeleton height={ 350 } />
        </div>
        <div className='col-md-3'>
          <Skeleton height={ 350 } />
        </div>
      </>
    )
  }

  const moveImages = (type) => {
    setActiveChartImgIndex(lst => {
      switch (type) {
        case 'next': return lst === chartsImages.length - 1 ? 0 : lst + 1
        case 'prev': return lst === 0 ? chartsImages.length - 1 : lst - 1
        default: return lst
      }
    })
  }

  if (token && subscription && subscription === 'none')
    return <Navigate to='/subscription' state={ { from: location } } replace />

  if (!token)
    return <Navigate to='/subscription' state={ { from: location } } replace />


  console.log(`https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/${chartsImages[0]}`)

  return (
    <>
      { loading ? (
        <Loading />
      ) : (
        <div className='py-12 mt-12 flex flex-col justify-center items-center text-center'>
          <div className='container'>
            <div className='mt-16 mb-5 section-title'>
              <h2 className='flex text-5xl justify-center align-center font-bold text-center'>
                <span className=''>{ data.section }</span>
              </h2>
              {/* <hr /> */ }
            </div>

            { chartsImages.length > 0 && <>

              <figure onClick={ handleClick } className='h-[40rem] mx-auto w-max overflow-y-auto relative imgContainer'>
                { chartsImages.map((image, i) => <img onClick={ () => {
                  setActiveChartImgIndex(i)
                  handleClick()
                } } src={ `https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/${image}` }
                  // style={ {
                  //   transform: `translateX(-50%) translateY(${(activeChartImgIndex - i) * 100}%)`
                  // } }
                  alt={ `${i}` } className='w-auto h-full transition-all' key={ i } />) }
              </figure>
              {/* { chartsImages.length > 1 && <div className="flex justify-center gap-3 mt-3">
                <button type="button" onClick={ () => moveImages("prev") } className={ `px-5 rounded-md flex items-center justify-center font-poppins text-center py-3 font-semibold bg-[#07358c] text-white transition-all hover:brightness-110` }>Previous</button>
                <button type="button" onClick={ () => moveImages("next") } className={ `px-5 rounded-md flex items-center justify-center font-poppins text-center py-3 font-semibold bg-[#07358c] text-white transition-all hover:brightness-110` }>Next</button>
              </div> } */}
            </>
            }

            { isOpen && (
              <PopChart
                onClose={ handleOnClose }
                visible={ isOpen }
                images={ chartsImages }
                name={ data.section }
              />
            ) }

            {/* { data.image && (
              <div className='flex justify-center flex-col items-center'>
                <img
                  src={ `https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/${data.image}` }
                  alt={ data.section }
                  className='h-[50rem] items-center'
                  loading='lazy'
                />

                <button
                  className=' items-center  mt-10  text-center h-10 px-6  text-white hover:bg-[#F17732] text-xl  bg-[--main-color] '
                  onClick={ handleClick }>
                  Open the chart in full screen
                </button>

                { isOpen && (
                  <PopChart
                    onClose={ handleOnClose }
                    visible={ isOpen }
                    image={ `https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/${data.image}` }
                    name={ data.section }
                  />
                ) }
              </div>
            ) } */}

            {/* <img src={} className=""  /> */ }
            { data.isOld === true ? (
              <div>
                { formatText?.length > 0 && (
                  <div className='h-full p-3'>
                    <h5 className='text-5xl font-bold mt-12 mb-8'>
                      { formatText[0] }
                    </h5>
                    <p className='text-2xl font-semibold'>{ formatText[1] }</p>
                    <hr className='bg-black mt-6' />
                    <ReadMore longContent={ formatText.slice(2) } />
                  </div>
                ) }
              </div>
            ) : (
              <div className='mt-8 mx-auto min-w-7xl p-4 bg-white  rounded-lg'>
                <div className='overflow-x-auto p-4'>
                  {/* Render the HTML content */ }
                  <HTMLRenderer htmlString={ data.body } />
                </div>
              </div>
            ) }
          </div>
        </div>
      ) }
    </>
  )
}

export default ChartDetail
